import React from "react";
import styles from "./benefitBlock.module.css";

const BenefitBlock = ({ icon, title, children }) => {
  return (
    <div className={styles.benefit}>
      {icon}
      <p>
        {title}
      </p>
      {children}
    </div>
  );
};

export default BenefitBlock;
