import React from "react";
import Layout from "../components/layout";
import styles from "./artykul.module.css";
import SEO from "../components/seo";

const Artykul = ({
  articleImage,
  articleImageAlt,
  title,
  children,
  metaTitle,
  metaDescription,
  keywords
}) => {
  return (
    <Layout>
      <SEO
        title={metaTitle ? metaTitle : title}
        description={metaDescription}
        keywords={keywords}
      />
      <article className={styles.articleWrapper}>
        <div className={styles.mainImgWrapper}>
          <img src={articleImage} alt={articleImageAlt} />
        </div>
        <h1>{title}</h1>
        {children}
      </article>
    </Layout>
  );
};

export default Artykul;
