import React from "react";
import Artykul from "../components/artykul";
import {  graphql } from "gatsby";
import Img from "gatsby-image";
import obrazekArtykulu from "../images/dobry-crm-dla-agencji-reklamowej-tlo.jpg";
import Lightbox from "../components/lightBox";
import { FaCalendarCheck, FaComments, FaCoins, FaUsers, FaChartLine, FaMedal } from "react-icons/fa";
import Container from "../components/container";
import BenefitBlock from "../components/benefitBlock";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import Columns from "../components/columns";

export const query = graphql`
  query {
    zdjecie1: file(relativePath: { eq: "kanban.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(relativePath: { eq: "kartaszansy.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
     zdjecie3: file(relativePath: { eq: "kalendarzzlecen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 95) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
const Crmagencjareklamowa = ({ data }) => {
  return (
    <Artykul
      title="Cechy dobrego systemu CRM dla agencji reklamowej"
      articleImage={obrazekArtykulu}
      keywords={["crm dla agencji reklamowej"]}
      articleImageAlt="dobry system crm dla agencji reklamowej."
      metaTitle="Cechy dobrego systemu CRM dla agencji reklamowej"
      metaDescription="Zarządzanie projektami marketingowymi ✅ Wsparcie sprzedaży produktów i usług reklamowych ✅ CRM dostosowany do agencji reklamowych ✅ Wypróbuj bezpłatnie!"
    >
      <br />
      <h2 style= {{
          textAlign: "left",
          marginBottom:20,
      }}>
        Jakie obszary usprawnia CRM w agencji reklamowej?
      </h2>
      <p>
       Działając w branży reklamowej, konieczna jest sprawna obsługa wielu projektów jednocześnie. <strong>To od czasu realizacji i 
       skutecznej komunikacji zależy zadowolenie klienta</strong>. Jeżeli Twoja firma tworzy projekty reklamowe czy świadczy usługi 
       drukarskie z zakresu reklamy, z pewnością potrzebuje systemu do sprawnego delegowania zleceń i zwiększania sprzedaży. 
       Empireo to system CRM dla agencji reklamowej, który wspiera branżę rozwiązaniem dopasowanym do jej konkretnych procesów.
      </p>

<h2 style= {{
          textAlign: "left",
          marginBottom:20,
      }}>
        Jakie procesy usprawnia CRM dla agencji reklamowej
      </h2>
      <p>
       CRM dla agencji reklamowej to intuicyjne narzędzie, z którego <strong>może korzystać zarówno dział handlowy, jak i realizacji</strong>. 
       Posiada funkcje do zarządzania sprzedażą, kontaktem z klientem oraz pracy projektowej. 
       To świetne rozwiązanie dla agencji, które mają w swojej ofercie m.in:</p>
<p><ul>
<li>projektowanie i druk materiałów reklamowych,</li>
<li>reklama outdoorowa,</li>
<li>produkcja gadżetów reklamowych,</li>
<li>projekty marketingowe i handlowe.</li>
</ul></p>


         <h2 style= {{
          textAlign: "left",
          marginBottom:20,
      }}>
        Jakie korzyści daje CRM dla agencji reklamowej?
      </h2>
        <BenefitBlockWrapper>
          <BenefitBlock
            icon={<FaChartLine size="45px" />}
            title="Wzrost przychodów firmy"
          >
          </BenefitBlock>
          <BenefitBlock
            icon={<FaCalendarCheck size="45px" />}
            title="Terminowe wykonywanie zleceń "
          >
          
          </BenefitBlock>
          <BenefitBlock icon={<FaComments size="45px" />} title="Usprawnienie komunikacji">
          
          </BenefitBlock>


        </BenefitBlockWrapper>
         <BenefitBlockWrapper>
          <BenefitBlock
            icon={<FaMedal size="45px" />}
            title="Poprawa jakości obsługi klienta "
          >

          </BenefitBlock>
          <BenefitBlock
            icon={<FaCoins size="45px" />}
            title="Obniżenie kosztów obsługi zleceń"
          >

          </BenefitBlock>
          <BenefitBlock icon={<FaUsers size="45px" />} title="Zwiększenie liczby klientów">
          
          </BenefitBlock>


        </BenefitBlockWrapper>
    
<h2 style= {{
          textAlign: "left",
          marginBottom:20,
      }}>
        Sprawnie zarządzaj  projektami reklamowymi
      </h2>
      <p>
       Kluczem do sukcesu agencji reklamowej jest sprawne zarządzanie projektami. System CRM umożliwia <strong>zarządzanie tablicami 
       Kanban</strong> z podziałem na etapy zleceń. Dzięki temu wystarczy jeden rzut oka aby zorientować się, jakie tematy są aktualnie 
       realizowane, kto za nie odpowiada i ile jeszcze czasu pozostało do ich zakończenia. </p>

<p>W zależności od tego, czym Twoja firma się zajmuje, możesz samodzielnie zdefiniować typy zleceń, odpowiadające zadaniom pracowników 
<strong>(np. projektowanie logo, wydruk ulotek)</strong>. Aby zbierać w nich tylko te informacje, które z perspektywy danego typu zlecenia są potrzebne, 
możesz skonfigurować własne atrybuty (np. termin realizacji, opis projektu, wymiary, załącz plik). W ten sposób, w jednym miejscu, 
pracownicy mają dostęp do wszystkich najważniejszych informacji i dokładnie wiedzą, czym w danym momencie powinni się zająć.</p>

<p>Ponadto, pod zleceniami rejestrowane są zdarzenia, gdzie dodaje się notatkę co zostało wykonane oraz ile czasu zajęła 
realizacja tego zadania. Dzięki temu zyskujesz wgląd w roboczogodziny poświęcone na projekty dla klientów, łatwiej jest zapanować 
nad budżetem firmowym, czy <strong>wyliczyć rentowność współpracy z&nbsp;danym klientem</strong>.

      </p>

       <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie1]}
        alts={[
          "Zarządzanie projektami w systemie dla agencji reklamowej - Kanban",
        ]}
      /> 
<br/>
<h2 style= {{
          textAlign: "left",
          marginBottom:20,
      }}>
       Zwiększ sprzedaż w swojej agencji dzięki CRM

      </h2>
      <p>
       Aby można było realizować projekt, najpierw trzeba pozyskać klienta. Ten proces najlepiej obsłużyć w&nbsp;systemie CRM dla agencji 
       reklamowej. Zbierzesz w nim wszystkie informacje o kontrahentach, których możesz segmentować np. ze względu na skalę lub rodzaj 
       usług, które go interesują. </p>
<p>Ponadto, podobnie jak w przypadku projektów, <strong>dostępne są widoki Kanban, które odzwierciedlają Twój lejek sprzedażowy</strong>. 
Dowiesz się na jakim etapie znajduje się konkretny lead, czy nie ma przeterminowanego zadania oraz na kiedy handlowiec przewiduje 
domknięcie tematu. Kampanie szans sprzedaży to ogromne ułatwienie zarówno dla pracowników działu handlowego, którzy w wygodny sposób 
notują wszystkie ustalenia z klientami, ale także dla kierowników czy zarządu. Z raportów możesz wyczytać na jakim etapie najczęściej 
tracicie szanse na sprzedaż oraz jakie są powody rezygnacji klientów. Mając takie dane, nieustannie będziesz rozwijać swój biznes, 
ponieważ system CRM dostarczy Twojej agencji kluczowych informacji z punktu widzenia zarządzania firmą.

      </p>
 <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie2]}
        alts={[
          "Karta szansy sprzedaży w systemie CRM dla agencji reklamowej",
        ]}
      /> 
      <br/>
      <h2 style= {{
          textAlign: "left",
          marginBottom:20,
      }}>
        System CRM dopasowany do branży reklamowej
      </h2>
      <p>
       Empireo jest systemem CRM kierowanym typowo do agencji reklamowych. Tablice Kanban, raporty poświęconego na zlecenie czasu, 
       czy <strong>wykres Gantta prezentujący zadania pracowników na poszczególne dni i miesiące</strong>, to narzędzia odpowiadające specyfice branży 
       reklamowej. Nie musisz zatem obawiać się, że działalność Twojej firmy nie znajdzie odzwierciedlenia w systemowych procesach - 
       konfigurujemy je właśnie z&nbsp;myślą o Was.</p>
<p>Rozumiemy wyzwania stojące przed firmami takimi jak Twoja, dlatego proponujemy sprawdzone rozwiązania, które usprawnią i przyspieszą 
realizację projektów dla Twoich klientów.  Oprócz defaultowej konfiguracji, możesz też samodzielnie dopracować swoje procesy - z naszą 
pomocą  lub bez - tak, aby w 100% odzwierciedlić charakter i sposób prowadzenia Twojego biznesu. 

      </p>

        <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie3]}
        alts={[
          "Wykres Gantta w systemie CRM dla agencji reklamowej",
        ]}
      />
      <br/>

      <h2 style= {{
          textAlign: "left",
          marginBottom:20,
      }}>
        Skuteczne wdrożenie systemu CRM w agencji reklamowej
      </h2>
      <p>
       System CRM dla agencji reklamowej to <strong>proste rozwiązanie pozwalające na szybkie rozpoczęcie pracy, bez długotrwałych i 
       skomplikowanych wdrożeń</strong>. Decydujesz którzy pracownicy mają korzystać z&nbsp;narzędzia, określasz ich uprawnienia do poszczególnych 
       sekcji programu i konfigurujesz najważniejsze elementy:
       <ul>
<li>typy i etapy zleceń/projektów,</li>
<li>atrybuty zleceń,</li>
<li>rodzaje zadań i zdarzeń,</li>
<li>kampanie szans sprzedaży,</li>
<li>pulpity pracowników.</li>
</ul>
Nie potrzebujesz wiedzy programistycznej ani wielu godzin by poukładać procesy w taki sposób, by odzwierciedlały te, 
zachodzące w Twojej firmie. Zmianę wprowadzisz w szybkim czasie i równie szybko zobaczysz jej efekty.
      </p>
<p><strong><a href="/demo/">Kliknij tutaj i wypróbuj bezpłatnie Empireo.</a></strong></p> 
 
    </Artykul>
  );
};

export default Crmagencjareklamowa;
